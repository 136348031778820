import { useMsal } from '@azure/msal-react';
import useShowAlert from 'hooks/useShowAlert/useShowAlert';
import { useEffect } from 'react';
import { axiosInstance } from './api';
import appConfiguration from './appConfiguration';
import { loginRequest } from './authConfig';
const AxiosInterceptor = ({ children }: any) => {
    const { instance, accounts } = useMsal();
    const { showError } = useShowAlert();
    useEffect(() => {
        const resInterceptor = (response: any) => {
            return response;
        }
        const errInterceptor = (error: any) => {
            if (error.response.status === 401) {
                showError("Sesión terminada");
                window.location.href = "/"
                sessionStorage.clear();
                localStorage.clear();
                return;
            }
            if (error.response.status === 403) {
                showError("No tiene permisos suficientes para ver está pantalla");
                window.location.href = "/inicio"
            }
            return Promise.reject(error);
        }
        const getCompanyDataFromLocalStorage = (config: any) => {
            const companyItem = localStorage.getItem('company');
            if (companyItem) {
                //Simular que se llamó desde el login
                const companyParse = JSON.parse(companyItem);
                // Del combo combo
                config.headers.company = companyParse.company;
                //cd05@gildemeister.pe (minusculas siempre)
                config.headers.alias = companyParse.alias;
                config.headers.userID = companyParse.userID;
            }
        }
        const useTokenLocal = async (config: any) => {
            getCompanyDataFromLocalStorage(config);
            return config;
        }
        const useTokenAD = async (config: any) => {
            const data = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            })
            config.headers.Authorization = data.accessToken ? `Bearer ${data.accessToken}` : '';
            getCompanyDataFromLocalStorage(config);
            return config;
        }
        if (appConfiguration.LocalLogin.active) {
            const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);
            const responseInterceptor = axiosInstance
                .interceptors.request.use(useTokenLocal)
            return () => {
                axiosInstance.interceptors.response.eject(interceptor)
                axiosInstance.interceptors.request.eject(responseInterceptor)
            };
        }
        else {
            const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);
            const responseInterceptor = axiosInstance
                .interceptors.request.use(useTokenAD)
            return () => {
                axiosInstance.interceptors.response.eject(interceptor)
                axiosInstance.interceptors.request.eject(responseInterceptor)
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts])
    return children;
}
export default axiosInstance;
export { AxiosInterceptor };