import { Navigate, Outlet } from 'react-router';

const PirvateRoute = () => {
    const userid: any = JSON.parse(localStorage.getItem("userid")!) || null;
    let pasarRoute = false;
    if (userid) {
        if (userid.validate) {
            pasarRoute = !pasarRoute;
        } else if (userid.clientValidate) {
            pasarRoute = !pasarRoute;
        }
    }
    return pasarRoute
        ? <Outlet />
        : <Navigate to='/cliente'></Navigate>
        
}
export default PirvateRoute;