import { createSlice } from '@reduxjs/toolkit';
export interface CompanyState {
    company: string,
    alias: string
}
export const CompanyEmptyState: CompanyState = {
    company: "",
    alias: ""
};
export const companySlice = createSlice({
    name: 'company',
    initialState: CompanyEmptyState,
    reducers: {
        createCompany: (state, action) => action.payload,
        updateCompany: (state: any, action: any) => ({ ...state, ...action.payload }),
        resetCompany: () => CompanyEmptyState
    }
});
export const { createCompany, updateCompany, resetCompany } = companySlice.actions;
export default companySlice.reducer;