import { Navigate, Outlet } from 'react-router';

const PrivateRouteClient = () => {
    const userid:any = JSON.parse( localStorage.getItem("userid")! ) || null;
    let pasarRoute = false;
    if( userid ){
        if( userid.validate && userid.active ){
            pasarRoute = !pasarRoute;
        }
    }
    return pasarRoute 
        ? <Outlet />
        : <Navigate to='/cliente'></Navigate>
}
export default PrivateRouteClient;