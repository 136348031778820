import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { msalConfig } from 'providers/authConfig';
import { AxiosInterceptor } from 'providers/axiosInterceptor';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/styles.css';
import reportWebVitals from './reportWebVitals';
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Sentry.init({
//   dsn: "https://7dfa8c2eb5fa4c9a99122865a59a519e@o398531.ingest.sentry.io/5423773",
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AxiosInterceptor>
        <App />
      </AxiosInterceptor>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();