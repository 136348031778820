import { createSlice } from '@reduxjs/toolkit';
export const RegisterUserEmptyState = {
  active: null,
  validate: null,
  clientValidate: null,
  currentTab: null,
};
export const registerUserSlice = createSlice({
  name: 'register-user',
  initialState: RegisterUserEmptyState,
  reducers: {
    setRegisterUser: (state:any, action:any) => ({ ...state, ...action.payload }),
    resetRegisterUser: () => RegisterUserEmptyState
  }
});
export const { setRegisterUser, resetRegisterUser } = registerUserSlice.actions;
export default registerUserSlice.reducer;