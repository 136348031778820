import api from "providers/api";
import { loadAbort } from "utilities";
export const getClientId = (clientId: number) => {
    const controller = loadAbort();
    return {
        call: api.cliente.getClienteId(clientId, controller.signal),
        controller
    };
};

export const getCompany = async () => {
    const data = await api.company.getCompany()
    return data.data.data;
};

export const getAllCompanies = () => {
    const controller = loadAbort();
    return {
        call: api.company.getAllCompanies(controller.signal),
        controller
    };
};