import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Image from 'react-bootstrap/Image'

import ErrorInfo from "assest/svg/Icon-errorInfo.svg";
import Error1 from "assest/svg/Icon-Error1.svg";
import Error2 from "assest/svg/Icon-Error2.svg";
import LoginSucces from "assest/svg/Icon-Loginsuccess.svg";
import IconConsentientook from "assest/svg/icono_thanks.svg";
import IconSaveClient from "assest/svg/Icon-saveCliente.svg";
import IconConfirm from "assest/svg/Icon-confirmSave.svg";
import IconRegister from "assest/svg/Icon-saveRegister.svg";
import LoginIcon from "assest/svg/icono_Login.svg";

import { render } from "react-dom";
import styled, { keyframes } from "styled-components";
import Swal from 'sweetalert2';
import Loader from '../../components/elements/Loader/Loader';

export default function useShowAlert() {

    const loginSuccessMessage = (message: string = '') => {
        return Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet" src='${LoginSucces}'/>`,
            timer: 5000,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet-login">${message}</p>`,
        })
    }

    const ConsentimiendoOk = (navigate: any, url: any) => {
        return Swal.fire({
            iconHtml: `<img class="img-sweet" src='${IconConsentientook}'/>`,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert-content',
                confirmButton: 'button-consent'
            },
            html: `<p class="title-sweet-consent">Gracias por gestionar el consentimiento del cliente</p> <p class="subtitle-sweet-consent">Por favor, continúa con la carga de datos requeridos</p>`,
        }).then((result) => {

            if (result.isConfirmed) {

                navigate(`${url}`)
            }

        })
    }

    const showSaveClient = (title: string) => {
        return Swal.fire({
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            iconHtml: `<img class="img-sweet" src='${IconSaveClient}'/>`,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert-save-confirm',
                confirmButton: 'button-cliente-confirm',
                cancelButton: 'button-cliente-cancel'
            },
            html: `<p class="title-sweet">¿${title}?</p>`,

        })
    }

    const showConfirm = (title: any) => {
        return Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet" src='${IconSaveClient}'/>`,
            timer: 2500,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet-login">${title}</p>`,
        })
    }

    const showSaveRegister = () => {
        return Swal.fire({
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            iconHtml: `<img class="img-sweet" src='${IconRegister}'/>`,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert-save-confirm',
                confirmButton: 'button-cliente-confirm',
                cancelButton: 'button-cliente-cancel'
            },
            html: `<p class="title-sweet">¿Estás seguro de que desea guardar este registro?</p>`,
        })
    }

    const showYesNoConfirmation = (message: string = '¿Está seguro que desea eliminar este registro?') => {
        return Swal.fire({
            showConfirmButton: true,
            confirmButtonText: 'Confirmar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            iconHtml: `<img class="img-sweet" src='${IconRegister}'/>`,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert-save-confirm',
                confirmButton: 'button-cliente-confirm',
                cancelButton: 'button-cliente-cancel'
            },
            html: `<p class="title-sweet">${message}</p>`,
        })
    }

    const showConfirmRegister = (message: any = 'Registro guardado con éxito') => {
        return Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet" src='${IconRegister}'/>`,
            timer: 2500,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet-login">${message}</p>`,
        })
    }

    const showOk = (message: string = "Registro guardado con éxito") => {
        return Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet" src='${IconRegister}'/>`,
            timer: 2500,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet-login">${message}</p>`,
        })
    }

    const showErrorMessage1 = useCallback((errorMessage: string) => {
        Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet-2" src='${Error1}'/>`,
            timer: 5000,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet">Error</p>  <p class="subtitle-sweet"> ${errorMessage} </p>`,

        })
    }, []);

    const showErrorMessage2 = useCallback((errorMessage: string) => {
        Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet-2" src='${Error2}'/>`,
            timer: 5000,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet">Error</p>  <p class="subtitle-sweet"> ${errorMessage} </p>`,

        })
    }, []);

    const showInfoMessage = (infoMessage: string) => {
        Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet-2" src='${ErrorInfo}'/>`,
            timer: 5000,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet">Información</p>  <p class="subtitle-sweet"> ${infoMessage} </p>`,

        })
    }

    /**
    * This is the foo function
    * @param recibe loading que es un boolean que indica si se esta cargando
    * @returns retorna void
    * @description Esta funcion sirve para mostrar un mensaje de cargando, se podra cancelar llamandola en false o lanzando otro mensaje de este hook
    */

    const showLoginSuccess = async () => {

        function ModalLoginSuccess() {
            const [show, setShow] = useState(true);

            const handleClose = () => setShow(false);
            useEffect(() => {
                setTimeout(() => {
                    handleClose();
                }, 2000);
            }, [])

            return (
                <Modal show={show}>
                    <Modal.Body className='p-5 text-center'><Image style={{ width: '13rem' }} fluid={true} src={LoginIcon} alt='¡Sesión iniciado con éxito!' /></Modal.Body>
                    <Modal.Title className="text-center p-5 pt-0">¡Sesión iniciado con éxito!</Modal.Title>
                </Modal>
            );
        }

        render(<ModalLoginSuccess />, document.getElementById("modal-root"));

        await setTimeout(() => { }, 2000);

        return;
    }
    const showLoginError = async (error: string) => {

        function ModalLoginError() {
            const [show, setShow] = useState(true);

            const handleClose = () => setShow(false);
            useEffect(() => {
                setTimeout(() => {
                    handleClose();
                }, 5000);
            }, [])

            return (
                <Modal show={show}>
                    <Modal.Body className='p-5 text-center'><Image style={{ width: '13rem' }} fluid={true} src='/static/svg/error-icon.svg' alt='Ingreso-Fallido' /></Modal.Body>
                    <Modal.Title className="text-center p-5 pt-0">{error}</Modal.Title>
                </Modal>
            );
        }
        render(<ModalLoginError />, document.getElementById("modal-root"));

        await setTimeout(() => { }, 2000);

        return;
    }


    const showError = useCallback((error: any) => {
        const message = error.response?.data?.message || error;
        Swal.fire({
            showConfirmButton: false,
            iconHtml: `<img class="img-sweet-2" src='${Error2}'/>`,
            timer: 5000,
            customClass: {
                icon: 'no-border',
                container: 'modal-text',
                popup: 'border-poup-sweetalert'
            },
            html: `<p class="title-sweet">Error</p>  <p class="subtitle-sweet"> ${message} </p>`,
        })
    }, []);

    const showLoading = (loading: boolean) => {

        function ModalLoading() {

            return (
                <>

                    {loading && <Loader />}

                </>
            )

        }

        render(<ModalLoading />, document.getElementById("modal-root"));


        return;
    }

    const showPreload = async () => {

        function Preload() {
            const [show, setShow] = useState(true);

            const handleClose = () => setShow(false);
            useEffect(() => {
                setTimeout(() => {
                    handleClose();
                }, 3000);
            }, [])

            return (

                <>
                    {show && <Loader />}
                </>

            )
        }
        render(<Preload />, document.getElementById("modal-root"));

        await setTimeout(() => { }, 2000);

        return;

    }

    return { loginSuccessMessage, showError, ConsentimiendoOk, showSaveClient, showConfirm, showInfoMessage, showErrorMessage1, showErrorMessage2, showConfirmRegister, showSaveRegister, showLoading, showLoginError, showLoginSuccess, showPreload, showOk, showYesNoConfirmation };
}

const spin = keyframes`
{
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Spinner = styled.div`
    border: 4px solid rgba(0, 0, 0, .1);
    border-radius: 50%;
    border-left-color: transparent;
    width: 5rem;
    height: 5rem;
    
    animation: ${spin} 1s ease infinite;
`