import { ConatinerLoading, SpinerText, BoxSvg } from "./styles";

import loaging from 'assest/loading/Loading_Gildemeister.svg';

export default function Loader() {
    return (
     <ConatinerLoading>
        {/* <img src={loaging} alt="" style={{ width: '150px' }} /> */}
    
        <BoxSvg className="loadingio-spinner-spinner-wkj07u6mc5o"><div className="ldio-tyjrttcyoj">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div></BoxSvg>

        <SpinerText>
          Cargando
        </SpinerText>
       
      </ConatinerLoading>
    );
}
