import { useIsAuthenticated } from '@azure/msal-react';
import appConfiguration from 'providers/appConfiguration';
import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loader from './elements/Loader/Loader';
export const RouteGuard = () => {
  const [activate, setActivate] = useState(true)
  setTimeout(() => setActivate(false), 500)
  const isAuthenticated = useIsAuthenticated();
  return (
    appConfiguration.LocalLogin.active ?
      <>
        {activate ? <Loader /> : appConfiguration.LocalLogin.active ? <Outlet /> : <Navigate replace to={`/`} />}
      </> :
      <>
        {activate ? <Loader /> : isAuthenticated ? <Outlet /> : <Navigate replace to={`/`} />}
      </>
  )
};
export default RouteGuard;