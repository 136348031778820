import PirvateRoute from 'routes/RoutesPrivate/Privateroute';

export const PrivateConsentiment = () => {
  // const [loading, SetLoading] = useState(true)
  // const navigate = useNavigate();
  // const { callEndpoint } = useFetchAndLoad();
  // const getClient = async () => {
  //   try {
  //     const user = JSON.parse(localStorage.getItem("userid") || '');
  //     const data = await getClientIdAsync(user.clientId, callEndpoint);
  //     if (data.validate === 1 && data.active === 1) {
  //       SetLoading(true);
  //       navigate("/cliente/cliente-lpdp");
  //     }
  //     SetLoading(false);
  //   } catch (error) {
  //     SetLoading(false);
  //   }
  // }
  // useEffect(() => {
  //   getClient();
  // }, [])
  return (
    <>
      {/* {loading && <Loader />}
      {!loading && <PirvateRoute />} */}
      <PirvateRoute />
    </>
  )
}
export default PrivateConsentiment