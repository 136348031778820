import styled from "styled-components";
import { Navbar,  Nav} from "react-bootstrap";
import { TEXT_PRIMARY } from 'components/constants';
import { BiMenuAltLeft } from "react-icons/bi";

export const NavBar = styled(Navbar)`
  min-height:60px;
  
`;

export const IconMenu = styled(BiMenuAltLeft)`
  color: ${TEXT_PRIMARY}
`;

export const OptionMenu = styled(Nav.Link)`
  color: ${TEXT_PRIMARY}
`;

